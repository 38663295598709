// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s7wPtdnZ3eFsYjTc5yvp {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #F2F2F2;
    padding: 4px 12px 4px 12px;
    background: #fad906f1;
    border-radius: 4px;
  }

  .ewRhGtbWeLrpUaKvpZnD { 
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #F2F2F2;
    padding: 4px 12px 4px 12px;
    background: #fd184a;
    border-radius: 4px;
  }

  .E7qeooHvn9ZCMIsn83bG { 
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #F2F2F2;
    padding: 4px 12px 4px 12px;
    background: #07da0b;
    border-radius: 4px;
  }
  .oUK1phjuZ6JcMWExA1zJ {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #F2F2F2;
    padding: 4px 12px 4px 12px;
    background: #0797da;
    border-radius: 4px;
  }`, "",{"version":3,"sources":["webpack://./src/components/StatusTrafficLight/StatusTrafficLight.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,qBAAqB;IACrB,cAAc;IACd,0BAA0B;IAC1B,qBAAqB;IACrB,kBAAkB;EACpB;;EAEA;IACE,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,qBAAqB;IACrB,cAAc;IACd,0BAA0B;IAC1B,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,qBAAqB;IACrB,cAAc;IACd,0BAA0B;IAC1B,mBAAmB;IACnB,kBAAkB;EACpB;EACA;IACE,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,qBAAqB;IACrB,cAAc;IACd,0BAA0B;IAC1B,mBAAmB;IACnB,kBAAkB;EACpB","sourcesContent":[".block_status_yelow {\r\n    font-family: 'Roboto';\r\n    font-style: normal;\r\n    font-weight: 400;\r\n    font-size: 16px;\r\n    line-height: 20px;\r\n    letter-spacing: 0.1px;\r\n    color: #F2F2F2;\r\n    padding: 4px 12px 4px 12px;\r\n    background: #fad906f1;\r\n    border-radius: 4px;\r\n  }\r\n\r\n  .block_status_red { \r\n    font-family: 'Roboto';\r\n    font-style: normal;\r\n    font-weight: 400;\r\n    font-size: 16px;\r\n    line-height: 20px;\r\n    letter-spacing: 0.1px;\r\n    color: #F2F2F2;\r\n    padding: 4px 12px 4px 12px;\r\n    background: #fd184a;\r\n    border-radius: 4px;\r\n  }\r\n\r\n  .block_status_green { \r\n    font-family: 'Roboto';\r\n    font-style: normal;\r\n    font-weight: 400;\r\n    font-size: 16px;\r\n    line-height: 20px;\r\n    letter-spacing: 0.1px;\r\n    color: #F2F2F2;\r\n    padding: 4px 12px 4px 12px;\r\n    background: #07da0b;\r\n    border-radius: 4px;\r\n  }\r\n  .block_status_blue {\r\n    font-family: 'Roboto';\r\n    font-style: normal;\r\n    font-weight: 400;\r\n    font-size: 16px;\r\n    line-height: 20px;\r\n    letter-spacing: 0.1px;\r\n    color: #F2F2F2;\r\n    padding: 4px 12px 4px 12px;\r\n    background: #0797da;\r\n    border-radius: 4px;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"block_status_yelow": `s7wPtdnZ3eFsYjTc5yvp`,
	"block_status_red": `ewRhGtbWeLrpUaKvpZnD`,
	"block_status_green": `E7qeooHvn9ZCMIsn83bG`,
	"block_status_blue": `oUK1phjuZ6JcMWExA1zJ`
};
export default ___CSS_LOADER_EXPORT___;
