// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OuIWn01qHRXYaGee6bFC {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
}

.LwRGBQifeICJwfTmhBzD {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  align-self: center;
  margin-left: 2.2%;
  margin-left: 12px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.IoovhUwevQHVV5qDlYjy {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.p1iNMzwAXSCPcuUvrO2e {
  height: 14px;
  width: 14px;
  margin-left: 6px;
}

.Hc1_skfX7ibEQZCWrtpE {
  color: var(--main-Gray-2-color);
  font-size: 14px;
  font-weight: 300;
  margin-left: 2.2%;
}

@media (max-width: 1240px) {
  .LwRGBQifeICJwfTmhBzD {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }

  .IoovhUwevQHVV5qDlYjy {
    width: 44px;
    height: 44px;
    border-radius: 100%;
  }
}

@media (max-width: 1000px) {
  /* .imgStyle {
    width: 20px;
    height: 20px;
  } */
}

@media (max-width: 480px) {
  /* .imgStyle {
    width: 30px;
    height: 30px;
  } */


}`, "",{"version":3,"sources":["webpack://./src/components/SearchCompaniesLittleBlock/companyRowElement/companyRowElement.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,kBAAkB;EAClB,iBAAiB;EACjB,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;EAClB,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,+BAA+B;EAC/B,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE;IACE,gBAAgB;IAChB,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,mBAAmB;EACrB;AACF;;AAEA;EACE;;;KAGG;AACL;;AAEA;EACE;;;KAGG;;;AAGL","sourcesContent":[".companyRowElement {\r\n  display: flex;\r\n  flex-direction: row;\r\n  cursor: pointer;\r\n  align-items: center;\r\n}\r\n\r\n.companyName {\r\n  font-family: \"Roboto\";\r\n  font-style: normal;\r\n  font-weight: 400;\r\n  font-size: 14px;\r\n  line-height: 20px;\r\n  color: #000000;\r\n  align-self: center;\r\n  margin-left: 2.2%;\r\n  margin-left: 12px;\r\n  white-space: nowrap;\r\n  overflow-x: hidden;\r\n  text-overflow: ellipsis;\r\n}\r\n\r\n.imgStyle {\r\n  width: 40px;\r\n  height: 40px;\r\n  border-radius: 100%;\r\n}\r\n\r\n.checkBoxIcon {\r\n  height: 14px;\r\n  width: 14px;\r\n  margin-left: 6px;\r\n}\r\n\r\n.vacancy_count {\r\n  color: var(--main-Gray-2-color);\r\n  font-size: 14px;\r\n  font-weight: 300;\r\n  margin-left: 2.2%;\r\n}\r\n\r\n@media (max-width: 1240px) {\r\n  .companyName {\r\n    font-weight: 600;\r\n    font-size: 16px;\r\n    line-height: 20px;\r\n  }\r\n\r\n  .imgStyle {\r\n    width: 44px;\r\n    height: 44px;\r\n    border-radius: 100%;\r\n  }\r\n}\r\n\r\n@media (max-width: 1000px) {\r\n  /* .imgStyle {\r\n    width: 20px;\r\n    height: 20px;\r\n  } */\r\n}\r\n\r\n@media (max-width: 480px) {\r\n  /* .imgStyle {\r\n    width: 30px;\r\n    height: 30px;\r\n  } */\r\n\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"companyRowElement": `OuIWn01qHRXYaGee6bFC`,
	"companyName": `LwRGBQifeICJwfTmhBzD`,
	"imgStyle": `IoovhUwevQHVV5qDlYjy`,
	"checkBoxIcon": `p1iNMzwAXSCPcuUvrO2e`,
	"vacancy_count": `Hc1_skfX7ibEQZCWrtpE`
};
export default ___CSS_LOADER_EXPORT___;
