var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import style from "./SubMenu.module.css";
import { useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useAppDispatch } from "../../redux/store";
import { getAboutUsTC } from "../../redux/aboutUsReducer";
export function SubMenu(props) {
    var _a;
    var dispatch = useAppDispatch();
    // временное решение, необходимо сделать код зависимым только от бэка
    var id = useParams().id;
    var param;
    if (id === "project") {
        param = 1;
    }
    if (id === "rules") {
        param = 3;
    }
    if (id === "advertisers") {
        param = 4;
    }
    if (id === "contacts") {
        param = 5;
    }
    useEffect(function () {
        if (param !== undefined) {
            dispatch(getAboutUsTC(param));
        }
    }, []);
    // ------------------------------------------------------------------
    var handleOnClick = function (id) {
        dispatch(getAboutUsTC(id));
    };
    return (_jsx("div", __assign({ className: style.subMenu }, { children: _jsx("div", __assign({ className: style.subMenu__wrapper }, { children: (_a = props.subMenu) === null || _a === void 0 ? void 0 : _a.menu.map(function (item, index) { return (_jsx(NavLink, __assign({ className: function (_a) {
                    var isActive = _a.isActive;
                    return (isActive ? style.link_active : style.link);
                }, onClick: function () { return handleOnClick(item.order); }, to: "/about/".concat(item.url) }, { children: item.name }), index)); }) })) })));
}
