var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
import style from "./SearchBlockForMobile.module.css";
import Search from "../../../assets/img/Search.png";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../redux/store";
import { useLocation } from "react-router-dom";
import { setSearchWord } from "../../../redux/globalSearchReducer";
import { useSelector } from "react-redux";
import { PATH } from "../../../constants";
export var SearchBlockForMobile = function (_a) {
    var changeOpenSearchBlock = _a.changeOpenSearchBlock, isSearchBlockAcive = _a.isSearchBlockAcive;
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var location = useLocation();
    var ref = useRef(null);
    var searchWord = useSelector(function (state) { return state.globalSearchReducer.searchWord; });
    var SubmitFunck = function () {
        if (searchWord.length === 0)
            return;
        dispatch(setSearchWord({ word: searchWord }));
        navigate("search_result_page/");
        changeOpenSearchBlock(!isSearchBlockAcive);
    };
    var ChangeValue = function (e) {
        var value = e.target.value;
        dispatch(setSearchWord({ word: value }));
    };
    var handleKeyPress = function (event) {
        if (event.key === "Enter") {
            dispatch(setSearchWord({ word: searchWord }));
            navigate(PATH.search_result);
        }
    };
    useEffect(function () {
        var handleClickOutside = function (event) {
            if (!ref.current.contains(event.target)) {
                setTimeout(function () {
                    changeOpenSearchBlock(false);
                }, 100);
            }
        };
        document.addEventListener("click", handleClickOutside, true);
        return function () {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, [changeOpenSearchBlock]);
    useEffect(function () {
        if (location.pathname !== "/search_result_page") {
            dispatch(setSearchWord({ word: "" }));
        }
    }, [location]);
    return (isSearchBlockAcive && (_jsx("div", __assign({ ref: ref, className: style.middle_block }, { children: _jsxs("div", __assign({ className: style.container_input }, { children: [_jsx(Link, __assign({ onClick: function (e) {
                        e.preventDefault();
                        SubmitFunck();
                    }, to: "/search_result_page" }, { children: _jsx("img", { className: style.icon_input, src: Search }) })), _jsx("input", { className: style.input_Search, placeholder: " \u041F\u043E\u0438\u0441\u043A", type: "text", onChange: ChangeValue, value: searchWord, onKeyDown: handleKeyPress })] })) }))));
};
