import { useSelector } from "react-redux";
import { checkWhoIsWho } from "../../utils/utill_methods/checkWhoIsWho";
export var useRedirectToProfileDependsOnWhoYouAre = function (authorType, id, fullId, visible_menu) {
    var userAuthorizedID = useSelector(function (state) { var _a, _b; return (_b = (_a = state.auth) === null || _a === void 0 ? void 0 : _a.userAuthInfo) === null || _b === void 0 ? void 0 : _b.id; });
    var userRole = useSelector(function (state) { var _a, _b; return (_b = (_a = state.auth) === null || _a === void 0 ? void 0 : _a.userAuthInfo) === null || _b === void 0 ? void 0 : _b.role; });
    var is_superuser = useSelector(function (state) { var _a, _b; return (_b = (_a = state.auth) === null || _a === void 0 ? void 0 : _a.userAuthInfo) === null || _b === void 0 ? void 0 : _b.is_superuser; });
    var urlToNvagiate;
    if (id === userAuthorizedID) {
        urlToNvagiate = checkWhoIsWho(userRole, is_superuser);
    }
    else {
        urlToNvagiate = "/".concat(authorType).concat(fullId).concat(activateFirstButtonInVisibleMenuList(visible_menu));
    }
    return urlToNvagiate;
};
var activateFirstButtonInVisibleMenuList = function (visibleList) {
    if (!(visibleList === null || visibleList === void 0 ? void 0 : visibleList.length))
        return "/nodata";
    switch (visibleList[0]) {
        case "Публикации":
            return "/publications";
        case "События":
            return "/events";
        case "Вакансии":
            return "/vacancies";
        case "О компании":
            return "/about";
        case "Обо мне":
            return "/about";
        default:
            return "";
    }
};
