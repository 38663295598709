var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import style from './StatusTrafficLight.module.css';
export var StatusTrafficLight = function (props) {
    var statusText = function (moderate) {
        switch (moderate) {
            case 0:
                return _jsx("p", __assign({ className: style.block_status_blue }, { children: "\u041C\u043E\u0439 \u0447\u0435\u0440\u043D\u043E\u0432\u0438\u043A" }));
                break;
            case 1:
                return _jsx("p", __assign({ className: style.block_status_yelow }, { children: "\u041D\u0430 \u043C\u043E\u0434\u0435\u0440\u0430\u0446\u0438\u0438" }));
                break;
            case 2:
                return _jsx("p", __assign({ className: style.block_status_red }, { children: "\u041E\u0436\u0438\u0434\u0430\u0435\u0442 \u043F\u0440\u0430\u0432\u043E\u043A" }));
                break;
            case 3:
                return _jsx("p", __assign({ className: style.block_status_green }, { children: "\u041E\u043F\u0443\u0431\u043B\u0438\u043A\u043E\u0432\u0430\u043D\u043E" }));
                break;
        }
    };
    return (_jsx("div", { children: statusText(props.moderate) }));
};
